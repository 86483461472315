// DESIGN APPROVED COLORS ------------------ //
$white: #ffffff;
$black: #000000;
$bodyBg: #fbfbfd;
$inputGray: rgba(151, 151, 151, 0.25);
$previewBorder: rgba(151, 151, 151, 0.36);
$placeholderGray: rgba(0, 0, 0, 0.3);
$transparentGray: rgba(0, 0, 0, 0.4);
$overlayBackground: rgba(0, 0, 0, 0.6);
$filterGrayBorder: #e1e1e1;
$grey: #cccccc;
$light-grey: #ececec;
$dark-grey: #757575;
$jet-black: #141212;
$gray-placeholder: #d3d3d3;
$gray-background: #f6f6f6;
$gray-border: #a9a9a9;
$gray-preview: #d8d8d8;
$ultra-grey: #dddddd;
$gray-placeholder: #757575;
$modal-background: #f9f9f9;
$zebra-table: #f9f9f9;
$orange: #f15a0b;
$dark-orange: #d44900;
$light-orange: #f7e8da;
$filterOrangeText: #ff5a00;
$crush-orange: #ff5900;
$red: #c11616;
$red-error: $red;
$dark-red: #d61212;
$light-error: #e80b0b;
$red-notification: $red;
$green: #16c132;
$dark-green: #0d9f37;
$light-green: #defce7;
$blue: #1e2758;
$dark-blue: #1f2758;
$tooltipBackground: rgba(56, 60, 66, 0.95);
$siteBackground: #f8f7f7;
$headerBackground: #f5f5f5;
$disabledToggle: #ceced2;
$widgetBorder: #e0e0e0;
$minimizeColor: #808080;
$widgetBackground: rgba(216, 216, 216, 0.42);
$scrollBarColor: rgba(0, 0, 0, 0.2);

// SHADOWS
$boxshadow-18: rgba(0, 0, 0, 0.18);
$boxshadow-20: rgba(0, 0, 0, 0.2);
$boxshadow-32: rgba(0, 0, 0, 0.32);
$boxshadow-33: rgba(0, 0, 0, 0.33);

// MEDIA QUERIES BRAKING POINTS
$page-width: 621px;

$brake_mobile: 768px;
$brake_tablet: 1024px;
$brake_desktop: 1281px;

$light-yellow: #fcf3d8;
$dark-yellow: #917420;
