@import "variables";

@mixin font-weight($weight: regular) {
  @if $weight == "light" {
    font-weight: 300;
  } @else if $weight == "medium" {
    font-weight: 500;
  } @else if $weight == "semi-bold" {
    font-weight: 600;
  } @else if $weight == "bold" {
    font-weight: 700;
  } @else if $weight == "extra-bold" {
    font-weight: 800;
  } @else if $weight == "black" {
    font-weight: 900;
  } @else {
    font-weight: 400;
  }
}

@mixin flexCenter() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin normalizeFont() {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

@mixin form-label($paddingBottom: 6px) {
  color: $black;
  display: block;
  padding-bottom: $paddingBottom;
  font-size: 13px;
  @include font-weight("semi-bold");
}

@mixin form-error {
  color: $red-error;
  margin-top: 8px;
  font-size: 14px;
}

@mixin card {
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  background-color: $white;
}

@mixin link-button($style: compact, $color: $black) {
  @include font-weight("semi-bold");
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  color: $color;
  padding: 0;

  &:disabled {
    cursor: not-allowed;
  }

  @if $style == compact {
    text-decoration: underline;
  } @else if $style == spacious {
    border-bottom: 1px solid $color;
    border-radius: 0;
    padding-bottom: 2px;
  }
}

@mixin tabs {
  margin-top: 4px;
  justify-content: flex-start;
}

@mixin customScrollBar {
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: $scrollBarColor transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollBarColor;
    border-radius: 4px;
  }
}

@mixin info-modal {
  text-align: left;
  line-height: 1;
  padding: 7px 16px 0 16px;
}

@mixin spinner($size: 32px) {
  width: $size;
  height: $size;
  animation: circ-anim 1s linear infinite;

  @keyframes circ-anim {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin dropDownParent($maxHeight, $padding: 12px 0 8px) {
  max-height: $maxHeight;
  padding: $padding;
}

@mixin dropDownChild($padding: 7px 16px 6px 15px, $fontSize: 13px, $lineHeight: 18px, $fontWeight: "semi-bold") {
  cursor: pointer;
  margin: 0;
  padding: $padding;
  font-size: $fontSize;
  line-height: $lineHeight;
  @include font-weight($fontWeight);
}

@mixin addressInputDropDown($inputHeight, $maxHeight) {
  & + div {
    top: $inputHeight !important;

    ul {
      @include dropDownParent($maxHeight);

      li {
        @include dropDownChild;
      }
    }
  }
}

@mixin checkbox(
  $size: 16px,
  $fontSize: 14px,
  $fontWeight: "semi-bold",
  $lineHeight: 1.86,
  $marginRight: 8px,
  $color: $black,
  $ellipsis: false
) {
  font-size: $fontSize;
  @include font-weight($fontWeight);
  line-height: $lineHeight;
  color: $color;
  display: flex;
  align-items: center;
  height: auto;
  padding: 0;
  user-select: none;

  > span {
    width: $size;
    height: $size;
    margin-right: $marginRight;
    position: static;
    flex-shrink: 0;
    border-radius: if($size > 16px, 5px, 3.3px);

    > img {
      width: if($size > 16px, 12px, 8px);
    }
  }

  > div {
    @if $ellipsis {
      @include ellipsis();
    } @else {
      white-space: nowrap;
    }
  }

  > input:checked + span {
    border-color: $filterOrangeText;
  }
}

@mixin textAreaScrollBar {
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: $scrollBarColor transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollBarColor;
    border-radius: 4px;
  }
}
