@import "../../styles/global/index";

@mixin radio() {
  padding-bottom: 0;
  padding-left: 24px;
  cursor: pointer;
  font-size: 16px;
  line-height: 1.63;
  font-weight: normal;

  > span {
    top: 4px;

    &,
    img {
      width: 16px;
      height: 16px;
    }
  }
}

@mixin check() {
  line-height: 1.63;
  font-size: 16px;
  color: $dark-grey;
  display: flex;
  align-items: center;
  font-weight: normal;
  padding-bottom: 0;
  padding-left: 33px;
  user-select: none;

  > span {
    width: 24px;
    height: 24px;
    top: 2px;
  }
}

@mixin panel() {
  background-color: transparent;
  font-size: 13px;
  line-height: normal;
  color: $black;
  padding: 0;
  margin-top: 16px;
  position: relative;
  @include font-weight("semi-bold");

  img {
    position: absolute;
    left: -20px;
    filter: brightness(0);
  }

  label {
    cursor: pointer;
  }
}

@mixin toolTipsInfo($top) {
  @include font-weight("semi-bold");
  padding: 15px 26px 20px;
  font-size: 14px;
  line-height: normal;
  opacity: 1;
  color: $white;

  &::after {
    top: $top;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right-width: 15px;
    left: -16px;
  }
}
