@import "../../../styles/global/index";
@import "../mixins";

div.container .form {
  .hidden {
    display: none;
  }

  text-align: left;

  .field {
    label {
      @include form-label(8px);

      .tips {
        color: $dark-grey;
      }
    }

    .error {
      color: $red-error;
    }

    .disabled {
      opacity: 0.5;
      cursor: default !important;
      color: $dark-grey !important;
    }

    &.name label {
      display: flex;
      justify-content: space-between;
      margin-top: 32px;
    }

    &.price,
    &.duration {
      margin-top: 32px;

      label {
        height: 16px;
        display: flex;
        align-items: center;
      }

      img {
        margin-left: 8px;
        cursor: pointer;
      }
    }

    &.price .tips {
      color: $dark-grey;
      font-size: 12px;
      line-height: 1.33;
      @include font-weight("semi-bold");
    }

    &.depositAmount {
      margin-top: 8px;
    }

    &.remainingAmountInfo {
      display: flex;
      align-items: center;
      margin-top: 40px;
      margin-left: 12px;

      label {
        font-size: 14px;
        margin-right: 16px;
        padding-bottom: 0;
      }

      .price {
        font-size: 14px;
        @include font-weight("semi-bold");
        color: $red;
      }
    }

    &.price input {
      width: 192px;
    }

    &.depositAmount input {
      width: 100%;
    }

    &.duration {
      white-space: nowrap;

      > div {
        max-width: 110px;
      }
    }

    &.sendInvoiceDays,
    &.sendInvoiceOptions {
      max-width: 200px;
    }

    .disabled &.sendInvoiceDays,
    .disabled &.sendInvoiceOptions,
    &.sendInvoiceDays.inactive,
    &.sendInvoiceOptions.inactive {
      > div > div {
        color: $dark-grey;
      }
    }

    &.details {
      margin-top: 32px;

      .toolTipsInfo {
        width: 296px;
        @include toolTipsInfo(88%);

        .body {
          svg {
            margin: 13px 0 0;
          }
        }
      }

      label {
        display: flex;
        align-items: center;
        padding-bottom: 0;
      }

      .tips {
        margin-left: 16px;
        margin-right: 8px;

        + img {
          cursor: pointer;
        }
      }

      > div:not(:empty) {
        margin: 6px 0 -32px;
      }
    }

    &.duration {
      .toolTipsInfo {
        @include toolTipsInfo(10%);
        width: 303px;
      }
    }

    .durationError,
    .shootTypeErrorError {
      > div > div {
        border-color: $red-error;
      }

      p {
        @include form-error;
      }
    }

    .description {
      position: relative;
      min-height: 50px;
      margin-bottom: 32px;

      label {
        position: absolute;
        right: 0;
        top: -17px;
      }

      .remove {
        width: 40px;
        height: 40px;
        padding: 9px;
        position: absolute;
        cursor: pointer;
        top: 5px;
        left: 100%;
      }

      .reorder {
        cursor: move;
        position: absolute;
        top: 10px;
        left: -36px;
        padding: 12px;
        width: 36px;
        height: 29px;

        &:before {
          content: " ";
          position: absolute;
          border: 1px solid $dark-grey;
          border-left: none;
          border-right: none;
          min-width: 12px;
          height: 5px;
        }
      }
    }

    &.sendInvoice {
      margin-top: 8px;

      label {
        padding-bottom: 8px;
      }

      .sendInvoiceDays,
      .sendInvoiceOptions {
        height: 50px;
      }

      .sendInvoiceDays > div {
        max-width: 75px;
        margin-left: -3px;
      }

      .sendInvoiceOptions > div {
        max-width: 185px;
        margin-left: -10px;

        > div > div {
          font-size: 16px;
        }
      }

      .option {
        margin-bottom: 3px;
      }
    }

    &.noPayment {
      padding-left: 28px;
    }

    &.availability {
      margin-top: 24px;

      .descriptions {
        display: flex;
        flex-direction: column;
        width: 325px;
        min-height: 48px;
        border-radius: 5px;
        border: solid 1px $light-grey;
        @include font-weight("semi-bold");
        font-size: 12px;
        line-height: 1.33;
        padding: 16px;

        .withMargin {
          margin-top: 15px;
        }
      }
    }

    &.shootLocation {
      margin-top: 32px;

      label {
        padding-bottom: 12px;
      }

      .radius {
        width: 73px;
        margin: 0 5px 0 8px;
        position: relative;

        input:disabled {
          color: $dark-grey;
        }

        > div {
          white-space: nowrap;
          position: absolute;
        }
      }
    }

    &.paymentType {
      margin-top: 2px;

      .option {
        height: 40px;
      }
    }

    &.additionalInfo,
    &.bookingApproval {
      label {
        padding-bottom: 7px;
      }

      .tips {
        margin-left: 13px;
      }
    }

    &.bookingApproval {
      margin-top: 16px;
    }

    label.askAdditionalInfo,
    label.bookingApproval {
      @include check;

      &.checked {
        color: $black;
      }
    }

    &.shootType label {
      padding-bottom: 2px;
    }
  }

  .depositSection {
    margin-bottom: 18px;
  }

  .agreement {
    border-radius: 5px;
    border: 1px solid rgba(151, 151, 151, 0.36);
    margin: 32px 0px 0px -28px;
    padding: 16px 0 26px 31px;

    h2 {
      font-size: 16px;
      @include font-weight("bold");
      line-height: normal;
      color: $black;
      margin-bottom: 14px;
    }

    em {
      @include font-weight("semi-bold");
    }
  }

  .btnAdd {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 10px;

    img {
      padding-right: 8px;
      cursor: pointer;
    }

    label {
      cursor: pointer;
      font-size: 16px;
      color: $black;
      @include font-weight("semi-bold");

      .tips {
        font-size: 13px;
        color: $dark-grey;
        margin-left: 10px;
      }
    }
  }

  .askAdditionalInfoDescription,
  .bookingApprovalDescription {
    font-size: 13px;
    padding-left: 33px;
    line-height: 1.54;
    margin-top: 2px;
  }

  .shootTypeDescription {
    font-size: 12px;
    line-height: 1.31;
    @include font-weight("semi-bold");
  }

  .highlight {
    font-weight: bold;
  }

  .panel {
    @include panel;

    .tips {
      color: $dark-grey;
    }
  }

  .field.sendInvoice,
  .field.shootLocation,
  .field.paymentType {
    .option {
      display: flex;
      align-items: center;
    }

    .radio {
      @include radio;

      &.active {
        color: $black;
      }
    }
  }
}
