$font-family: "Nunito Sans", sans-serif;

input::-ms-clear {
  display: none;
}

html.widget {
  overflow: hidden;

  body {
    background: transparent;
  }

  &,
  body {
    height: 100%;
    font-family: $font-family;
  }
}

html.main {
  overflow-y: scroll;

  &:not(.fastScroll) {
    scroll-behavior: smooth;
  }

  body {
    background: #fff;
  }

  &,
  body {
    height: auto;
    font-family: $font-family;
  }
}

html.widget *,
html.main * {
  -webkit-tap-highlight-color: transparent;
}
